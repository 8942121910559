<template>
  <v-container style="margin-left: 60px;">
    <v-row class="d-flex align-center justify-center">
      <v-col class="d-flex justify-center">
        <div class="text-center align-center justify-center">
          <p class="text-align-center display-1 font-weight-thin">
            Ajouter un entretien (format {{ type == 'audio' ? 'Audio' : 'Texte'}})
          </p>
          <v-form ref="form" v-on:submit.prevent="upload()">
            <FormAlert :info="{alert}"></FormAlert>
            <v-row class="d-flex justify-center">
              <v-col class="d-flex">
                <v-progress-circular v-if="loading" :size="50"
                color="primary" indeterminate></v-progress-circular>
                <v-file-input v-else v-model="file" how-size label="Fichier" @change="onFileChange">
                </v-file-input>
                <v-radio-group dense class="ml-5" v-model="language" mandatory row>
                  <v-radio label="Français" value="fr"></v-radio>
                  <v-radio label="Anglais" value="en"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <div class="text-right">
              <v-btn @click="previous()" color="red" class="mr-4" text dark>Annuler</v-btn>
              <v-btn :disabled="!file" :dark="!!file"
              type="submit" color="blue" class="mr-4" outlined>
              Envoyer
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-col>
    <v-col>
      <p class="title">Formats acceptés</p>
        <ul>
          <li><pre>[Participant]: [discours]</pre> 1 tour/ligne</li>
          <li>Export texte Elan</li>
          <li>Format STM (export Transcriber)</li>
          <li>Format TRS (XML) (déconseillé)</li>
        </ul>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';
import FormAlert from './Alert.vue';

export default {
  name: 'UploadForm',
  mixins: [MixinInterviews],
  components: { FormAlert },
  props: {
    type: String,
    interviewId: String,
    patientId: String,
  },
  data: () => ({
    loading: false,
    file: null,
    formData: null,
    language: 'fr',
  }),
  methods: {
    onFileChange(e) {
      this.formData = new FormData();
      this.formData.append('files', e);
    },

    previous() {
      this.$router.go(-1);
    },

    async upload() {
      this.loading = true;
      const response = await this.uploadInterview(
        this.patientId,
        this.interviewId,
        this.formData,
        this.type,
        this.language,
      );

      this.loading = false;

      if (!response.ok) {
        this.showAlertMessage('error', await response.text());
      } else {
        this.previous();
      }
    },
  },
};
</script>
